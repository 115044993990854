<template>
  <v-container fluid>
    <v-form class="pt-5">
      <v-row>
        <v-col cols="12">
          <base-material-card
            color="primary"
            icon="mdi-map-marker-question"
            inline
            class="px-5 py-3"
          >
            <template v-slot:after-heading>
              <div class="display-1 font-weight-light">
                Simbologia do Layer: {{ relatorio.nome }}
              </div>
            </template>
            <v-row
              align="center"
              no-gutters
              class="ml-0"
            >
              <v-col
                cols="12"
                id="selecao-simbologia"
              >
                <v-radio-group
                  v-model="tipoSimbologia"
                  mandatory
                  row
                >
                  <v-radio
                    label="Simples"
                    value="simples"
                  />
                  <v-radio
                    label="Categorizada"
                    value="categorizada"
                    :disabled="!simbologiaCategorizadaHabilitada"
                  />
                  <v-radio
                    label="Graduada"
                    value="graduada"
                    :disabled="!simbologiaGraduadaHabilitada"
                  />
                  <v-radio
                    label="Mapa de Calor"
                    value="mapa_calor"
                    :disabled="!simbologiaMapaCalorHabilitada"
                  />
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row
              align="center"
              no-gutters
              class="ml-0"
            >
              <v-col cols="12">
                <v-alert
                  dense
                  outlined
                  type="info"
                  color="blue"
                  class="mb-0 py-1 px-1"
                >
                  <div v-if="tipoSimbologia == 'simples'">
                    Escolha simbologia simples caso queira que as feições a
                    serem plotadas no mapa tenham a mesma cor e simbologia
                    gráfica.
                  </div>
                  <div v-else-if="tipoSimbologia == 'categorizada'">
                    Escolha simbologia categorizada caso queira alterar as cores
                    e símbolos de acordo com a classificação dos dados de um
                    determinado
                    <span class="underline">campo não numérico</span>, como por
                    exemplo escolher cores diferentes para polígonos que
                    representam regiões de um país.
                  </div>
                  <div v-else-if="tipoSimbologia == 'graduada'">
                    Escolha simbologia graduada caso queira escolher um
                    <span class="underline">campo numérico</span> para formar
                    intervalos de dados, podendo pintar cada intervalo com cores
                    distintas.
                  </div>
                  <div v-else>
                    Escolha simbologia mapa de calor caso queira escolher um
                    <span class="underline">campo numérico</span> para
                    identificar <span class="underline">áreas quentes</span> em
                    seus dados, como por exemplo uma região do país com maior
                    densidade populacional.
                  </div>
                </v-alert>
              </v-col>
            </v-row>
            <br />
          </base-material-card>
        </v-col>
      </v-row>
      <v-row id="row-parametros">
        <v-col
          md="6"
          cols="12"
        >
          <base-material-card
            color="primary"
            icon="mdi-playlist-edit"
            inline
            class="px-5 py-3"
            style="min-height: 500px"
          >
            <template v-slot:after-heading>
              <div class="display-1 font-weight-light">
                Parâmetros Simbologia
                <span v-if="tipoSimbologia == 'simples'"> Simples </span>
                <span v-else-if="tipoSimbologia == 'categorizada'">
                  Categorizada
                </span>
                <span v-else-if="tipoSimbologia == 'graduada'"> Graduada </span>
                <span v-else> Mapa de Calor </span>
              </div>
            </template>
            <v-row v-if="tipoDadoGeografico">
              <v-col
                cols="12"
                id="form-parametros"
              >
                <form-parametros-simbologia-simples
                  :tipoDadoGeografico="tipoDadoGeografico"
                  v-if="tipoSimbologia === 'simples'"
                />
                <form-parametros-simbologia-categorizada
                  :relatorio="relatorio"
                  :campos="dadosRelatorio.cabecalho"
                  :tiposDadosCampos="dadosRelatorio.tiposDados"
                  :tipoDadoGeografico="tipoDadoGeografico"
                  v-else-if="tipoSimbologia === 'categorizada'"
                />
                <form-parametros-simbologia-graduada
                  :relatorio="relatorio"
                  :campos="dadosRelatorio.cabecalho"
                  :tiposDadosCampos="dadosRelatorio.tiposDados"
                  :tipoDadoGeografico="tipoDadoGeografico"
                  v-else-if="tipoSimbologia === 'graduada'"
                />
                <form-parametros-simbologia-mapa-calor
                  :relatorio="relatorio"
                  :campos="dadosRelatorio.cabecalho"
                  :tiposDadosCampos="dadosRelatorio.tiposDados"
                  v-else
                />
              </v-col>
            </v-row>
          </base-material-card>
        </v-col>
        <v-col
          md="6"
          cols="12"
        >
          <base-material-card
            color="primary"
            icon="mdi-map-outline"
            inline
            class="px-5 py-3"
            style="min-height: 500px"
          >
            <template v-slot:after-heading>
              <div class="display-1 font-weight-light">
                Visualização do mapa
              </div>
            </template>
            <v-row>
              <v-col cols="12">
                <v-alert
                  dense
                  outlined
                  type="info"
                  color="blue"
                  class="mb-3 py-1 px-1"
                >
                  Esse mapa exibe no máximo 500 registros.
                </v-alert>
                <div id="mapa-simbologia">
                  <preview-mapa-simbologia-simples
                    :relatorio="relatorio"
                    :linhasRelatorio="linhasRelatorioAssoc"
                    v-if="tipoSimbologia === 'simples'"
                  />
                  <preview-mapa-simbologia-categorizada
                    :relatorio="relatorio"
                    :linhasRelatorio="linhasRelatorioAssoc"
                    v-else-if="tipoSimbologia === 'categorizada'"
                  />
                  <preview-mapa-simbologia-graduada
                    :relatorio="relatorio"
                    :linhasRelatorio="linhasRelatorioAssoc"
                    v-else-if="tipoSimbologia === 'graduada'"
                  />
                  <preview-mapa-simbologia-mapa-calor
                    :relatorio="relatorio"
                    :linhasRelatorio="linhasRelatorioAssoc"
                    v-else
                  />
                </div>
              </v-col>
            </v-row>
          </base-material-card>
        </v-col>
      </v-row>
      <v-row id="row-relatorios-pre-view">
        <v-col cols="12">
          <relatorios-pre-view
            :relatorioId="relatorio.id"
            :relatorioNome="relatorio.nome"
          />
        </v-col>
      </v-row>
      <v-row id="row-btn-save">
        <v-col cols="12">
          <v-btn
            id="btn-save"
            color="primary"
            min-width="100"
            style="float: right"
            class="mt-n5 mr-0"
            @click="salvar()"
            :disabled="btnSaveDisabled"
            :loading="loading"
          >
            Salvar
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import RelatoriosService from '@/services/RelatoriosService';
import tourMixins from '@/mixins/tourMixins';
import routes from '@/store/modules/routes';

export default {
  mixins: [tourMixins],

  components: {
    FormParametrosSimbologiaSimples: () =>
      import(
        '@/components/layers-personalizados/formularios/FormParametrosSimbologiaSimples.vue'
      ),
    FormParametrosSimbologiaCategorizada: () =>
      import(
        '@/components/layers-personalizados/formularios/FormParametrosSimbologiaCategorizada.vue'
      ),
    FormParametrosSimbologiaGraduada: () =>
      import(
        '@/components/layers-personalizados/formularios/FormParametrosSimbologiaGraduada.vue'
      ),
    FormParametrosSimbologiaMapaCalor: () =>
      import(
        '@/components/layers-personalizados/formularios/FormParametrosSimbologiaMapaCalor.vue'
      ),
    PreviewMapaSimbologiaSimples: () =>
      import(
        '@/components/layers-personalizados/previews-mapas/PreviewMapaSimbologiaSimples.vue'
      ),
    PreviewMapaSimbologiaCategorizada: () =>
      import(
        '@/components/layers-personalizados/previews-mapas/PreviewMapaSimbologiaCategorizada.vue'
      ),
    PreviewMapaSimbologiaGraduada: () =>
      import(
        '@/components/layers-personalizados/previews-mapas/PreviewMapaSimbologiaGraduada.vue'
      ),
    PreviewMapaSimbologiaMapaCalor: () =>
      import(
        '@/components/layers-personalizados/previews-mapas/PreviewMapaSimbologiaMapaCalor.vue'
      ),
    RelatoriosPreView: () => import('@/components/relatorios/RelatoriosPreView')
  },

  data: () => ({
    relatorio: {},
    tipoSimbologia: 'simples',
    tipoDadoGeografico: '',
    dadosRelatorio: {
      cabecalho: [],
      dados: []
    },
    arrTour: [
      {
        element: '#selecao-simbologia',
        intro: 'Aqui você pode selecionar a simbologia do layer.',
        scrollTo: 'tooltip',
        position: 'bottom'
      },
      {
        element: '#form-parametros',
        intro:
          'Cada simbologia possui parâmetros para configuração, como as cores e ícones que serão utilizados pelo layer.',
        scrollTo: 'tooltip',
        position: 'bottom'
      },
      {
        element: '#mapa-simbologia',
        intro:
          'Aqui você consegue visualizar como ficará o layer após a configuração dos parâmetros de simbologia.',
        scrollTo: 'tooltip',
        position: 'left'
      }
    ],
    loading: false
  }),

  mounted() {
    this.getRelatorio(this.$route.params.id);
    this.iniciarTourPagina();
  },

  computed: {
    linhasRelatorioAssoc() {
      let cabecalho = this.dadosRelatorio.cabecalho;
      let linhas = this.dadosRelatorio.dados.map((linha) => {
        let novaLinha = {};
        linha.forEach((dado, indice) => (novaLinha[cabecalho[indice]] = dado));
        return novaLinha;
      });
      return linhas;
    },

    btnSaveDisabled() {
      switch (this.tipoSimbologia) {
        case 'simples':
          return !this.simbologiaConfig.simples.corFeicoes;
        case 'categorizada':
          return !(
            this.simbologiaConfig.categorizada.campoParaClassificacao &&
            this.simbologiaConfig.categorizada.iconeCorOutrasFeicoes.cor &&
            Object.keys(this.simbologiaConfig.categorizada.iconesCoresFeicoes)
              .length
          );
        case 'graduada':
          return !(
            this.simbologiaConfig.graduada.campoParaClassificacao &&
            this.simbologiaConfig.graduada.iconeCorOutrasFeicoes.cor &&
            this.simbologiaConfig.graduada.iconesCoresFeicoes.length &&
            this.simbologiaConfig.graduada.valorMinimo <
              this.simbologiaConfig.graduada.valorMaximo
          );
        default:
          return !(
            this.simbologiaConfig.mapa_calor.campoParaClassificacao &&
            this.simbologiaConfig.mapa_calor.raioPontos &&
            this.simbologiaConfig.mapa_calor.desfoquePontos
          );
      }
    },

    simbologiaConfig() {
      return this.$store.getters.getSimbologiaConfig;
    },

    simbologiaCategorizadaHabilitada() {
      return this.linhasRelatorioAssoc.length > 1;
    },

    simbologiaGraduadaHabilitada() {
      return this.linhasRelatorioAssoc.length > 1;
    },

    simbologiaMapaCalorHabilitada() {
      return (
        this.tipoDadoGeografico === 'PONTO' &&
        this.linhasRelatorioAssoc.length > 1
      );
    }
  },

  methods: {
    getRelatorio(id) {
      RelatoriosService.getRelatorio(id)
        .then((response) => {
          this.relatorio = response.data.data;
          this.tipoDadoGeografico = this.relatorio.tipo_dado_geografico;
          this.tipoSimbologia = this.relatorio.tipo_simbologia.toLowerCase();
          this.getDadosRelatorio(id);
        })
        .catch(() =>
          this.$toast.error('Erro ao recuperar o layer.', '', {
            position: 'topRight'
          })
        );
    },

    getDadosRelatorio(id) {
      RelatoriosService.preVisualizar(id, 1)
        .then((response) => {
          this.dadosRelatorio = {
            cabecalho: response.data.cabecalho,
            dados: response.data.dados,
            tiposDados: JSON.parse(response.data.arrCamposTiposDados)
          };
          this.setSimbologiaConfig();
        })
        .catch(() =>
          this.$toast.error('Erro ao baixar os dados do layer.', '', {
            position: 'topRight'
          })
        );
    },

    setSimbologiaConfig() {
      this.$store.dispatch('resetaSimbologiaConfig');

      const simbologiaConfig = JSON.parse(this.relatorio.simbologia_config);

      switch (this.tipoSimbologia) {
        case 'simples':
          this.$store.dispatch(
            'setSimbologiaConfigSimplesCorFeicoes',
            simbologiaConfig.corFeicoes
          );
          this.$store.dispatch(
            'setSimbologiaConfigSimplesIconeFeicoes',
            simbologiaConfig.iconeFeicoes
          );
          break;
        case 'categorizada':
          this.$store.dispatch(
            'setSimbologiaConfigCategorizadaCampoParaClassificacao',
            simbologiaConfig.campoParaClassificacao
          );
          this.$store.dispatch(
            'setSimbologiaConfigCategorizadaIconeCorOutrasFeicoes',
            simbologiaConfig.iconeCorOutrasFeicoes
          );
          this.$store.dispatch(
            'setSimbologiaConfigCategorizadaIconesCoresFeicoes',
            simbologiaConfig.iconesCoresFeicoes
          );
          break;
        case 'graduada':
          this.$store.dispatch(
            'setSimbologiaConfigGraduadaCampoParaClassificacao',
            simbologiaConfig.campoParaClassificacao
          );
          this.$store.dispatch(
            'setSimbologiaConfigGraduadaIconeCorOutrasFeicoes',
            simbologiaConfig.iconeCorOutrasFeicoes
          );
          this.$store.dispatch(
            'setSimbologiaConfigGraduadaIconesCoresFeicoes',
            simbologiaConfig.iconesCoresFeicoes
          );
          this.$store.dispatch(
            'setSimbologiaConfigGraduadaValorMinimo',
            simbologiaConfig.valorMinimo
          );
          this.$store.dispatch(
            'setSimbologiaConfigGraduadaValorMaximo',
            simbologiaConfig.valorMaximo
          );
          break;
        default:
          this.$store.dispatch(
            'setSimbologiaConfigMapaCalorCampoParaClassificacao',
            simbologiaConfig.campoParaClassificacao
          );
          this.$store.dispatch(
            'setSimbologiaConfigMapaCalorRaioPontos',
            simbologiaConfig.raioPontos
          );
          this.$store.dispatch(
            'setSimbologiaConfigMapaCalorDesfoquePontos',
            simbologiaConfig.desfoquePontos
          );
      }
    },

    salvar() {
      this.loading = true;

      const data = {
        tipo_simbologia: this.tipoSimbologia,
        simbologia_config: this.simbologiaConfig[this.tipoSimbologia]
      };

      RelatoriosService.saveSimbologiaConfig(this.$route.params.id, data)
        .then(() => {
          this.$toast.success(
            'Simbologia do layer configurado com sucesso.',
            '',
            { position: 'topRight' }
          );
          this.$router.replace({
            name: routes.state.appRoutes['LAYERS_PERSONALIZADOS_HISTORICO']
          });
        })
        .catch(() => {
          this.$toast.error(
            'Erro ao salvar a configuração da simbologia do layer.',
            '',
            { position: 'topRight' }
          );
        })
        .finally(() => (this.loading = false));
    },

    iniciarTourPagina() {
      if (this.tourFuncCalled) return;

      this.iniciarTour(this.arrTour);
    }
  }
};
</script>

<style scoped>
.underline {
  text-decoration: underline;
}
</style>
